import React from 'react'
import './base.css'
import Container from './container'
import Navigation from './navigation'

class Template extends React.Component {
  componentDidMount(){
    var vglnk = {key: '75a413252d51e8266bbb179992d3c9ff'};
    var s = document.createElement('script');
                        s.type = 'text/javascript';
                        s.async = true;
                        s.src = '//cdn.viglink.com/api/vglnk.js';
                        document.body.appendChild(s); 
  }
  render() {
    const { children } = this.props

    return (
      <Container>
        <Navigation />
        {children}
      </Container>
    )
  }
}

export default Template
